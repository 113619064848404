import React, { useEffect, useState } from 'react';

import { Height } from '@mui/icons-material';


const Banner = () => {
    

    return (
        <div style={{ height: '100%', maxHeight: '500px'}}>
            <h2>AlgoSolved!</h2>
        </div>

    );
}

export default Banner;
